/** Import React and 3rd party libs */
import React, { useState } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "react-apollo";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";

// @material-ui/icons
import ProfilePic from "components/ProfilePic/ProfilePic.jsx";
import AddAlert from "@material-ui/icons/AddAlert";

// Components
import InviteUser from "components/Modal/InviteUser";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { getUserName } from "services/UserService";
import {
  getItemTextClass,
  getListItemTextClass,
  getUserWrapperClass,
  LinkItem
} from "./SidebarUtility";

// Queries
import { InviteUserMutation } from "api/mutations";

// Assets
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions

/**
 * User Component - user box and dropdown on sidebar
 */
const UserComponent = ({
  classes,
  user,
  miniActive,
  bgColor,
  runTour,
  openAvatar,
  openCollapse,
  inviteUser
}) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [notification, setNotification] = useState({
    color: "success",
    message: "User was invited.",
    isOpen: false
  });
  const { profile } = user;
  const { profilePicUrl } = profile;
  const itemText = getItemTextClass(classes, miniActive);
  const userWrapperClass = getUserWrapperClass(classes, bgColor);
  const userName = getUserName(profile);
  const fadeIn = profilePicUrl ? true : false;

  const handleClose = () => setCreateModalOpen(false);
  const handleOpen = () => setCreateModalOpen(true);

  const handleInvite = async (name, email, phone, groups) => {
    // Name is validated in component, so just save and refresh
    let error = "No results";
    let results;
    try {
      results = await inviteUser(name, email, phone, groups);
      error = results && results.error;
    } catch (err) {
      error = err;
    }

    if (error) {
      setNotification({
        color: "danger",
        message: `Error inviting user: ${error}`,
        isOpen: true
      });
      return console.error(`Error loading data [${error}]`);
    }
    setCreateModalOpen(false);
    setNotification({
      color: "success",
      message: "User was successfully invited.",
      isOpen: true
    });
  };

  const UserNavLinks = [
    {
      to: "/app/my-profile",
      itemMini: "MP",
      name: "My Profile"
    },
    // {
    //   to: "/app/my-profile?edit=true",
    //   itemMini: "EP",
    //   name: "Edit Profile"
    // },
    {
      to: "/app/org-profile",
      itemMini: "OP",
      name: "Org Profile"
    },
    {
      clickFn: runTour,
      itemMini: "TT",
      name: "Take a Tour",
      hide: "sm"
    },
    {
      to: "/pages/contact",
      itemMini: "GH",
      name: "Get Help"
    },
    {
      clickFn: () => user.logout(),
      itemMini: "LO",
      name: "Sign Out"
    },
    {
      id: "invite-user",
      name: "Invite User",
      icon: "plus",
      clickFn: () => handleOpen(),
      itemMini: "+"
    }
  ];

  return (
    <div id="my-profile" className={userWrapperClass}>
      <div className={classes.photo}>
        <Fade in={fadeIn} timeout={3000}>
          <ProfilePic
            profilePicUrl={profilePicUrl}
            theme={{
              photoImg: {
                maxWidth: "100%",
                width: "100%",
                verticalAlign: "middle",
                border: "0"
              }
            }}
          />
        </Fade>
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.userItem}>
          <div
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={() => openCollapse("openAvatar")}
          >
            <ListItemText
              primary={userName}
              secondary={
                <b className={getListItemTextClass(classes, openAvatar)} />
              }
              disableTypography={true}
              className={itemText + " " + classes.userItemText}
            />
          </div>
          <Collapse in={openAvatar} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              {UserNavLinks.map(link => {
                return (
                  <LinkItem
                    key={link.itemMini}
                    classes={classes}
                    link={link}
                    miniActive={miniActive}
                  />
                );
              })}
            </List>
          </Collapse>
        </ListItem>
      </List>
      <InviteUser
        isOpen={createModalOpen}
        handleConfirm={handleInvite}
        handleClose={handleClose}
      />
      <Snackbar
        place="bc"
        color={notification.color}
        icon={AddAlert}
        message={notification.message}
        open={notification.isOpen}
        closeNotification={() => {
          const newNotification = Object.assign({}, notification, {
            isOpen: false
          });
          setNotification(newNotification);
        }}
        close
      />
    </div>
  );
};

// Data Connectors
const _InviteUser = graphql(InviteUserMutation, {
  props: ({ mutate }) => ({
    inviteUser: (name, email, phone, groups) => {
      return mutate({
        variables: {
          name,
          email,
          phone,
          groups
        }
      });
    }
  })
});

export default compose(_InviteUser, withStyles(sidebarStyle))(UserComponent);
