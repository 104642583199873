import React from "react";
import PropTypes from "prop-types";

import { graphql } from "react-apollo";

import { flowRight as compose } from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";

// Components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// Subcomponents
import UserComponent from "./SidebarComponents/UserComponent";
import OrganizationComponent from "./SidebarComponents/OrganizationComponent";
import LargeSidebar from "./SidebarComponents/LargeSidebar";
import SmallSidebar from "./SidebarComponents/SmallSidebar";
import Links from "./Links/Links";

// Services
import {
  getItemIcon,
  getItemTextClass,
  getNavLinkColorClass,
  createdPinnedTemplateLinks,
  createdPinnedDashboardLinks
} from "./Links/LinksUtility";
import {
  ChecklistNavType,
  createNewChecklist
} from "services/ChecklistService";

// Queries
import { ChecklistTemplatesQuery, LogbooksQuery } from "api/queries";

// Assets
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

// Functions
const PinnedChecklists = ({ classes, dynamicLinks, label, miniActive }) => {
  const smallLabel = label === "Templates" ? "TMP" : "ACT";
  return (
    <div className={classes.pinnedChecklists}>
      <div
        className={miniActive ? classes.activeLabelSmall : classes.activeLabel}
      >
        {miniActive ? smallLabel : label}
      </div>
      {dynamicLinks}
    </div>
  );
};

const PinnedDashboard = ({ classes, dynamicLinks, label, miniActive }) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <div
        className={miniActive ? classes.activeLabelSmall : classes.activeLabel}
      >
        {miniActive ? "DSH" : label}
      </div>
      {dynamicLinks}
    </div>
  );
};

const NewChecklistButton = ({
  classes,
  miniActive,
  color,
  activeRoute,
  history
}) => {
  const path = "checklist?edit=true";
  const navLinkColorClasses = getNavLinkColorClass(
    classes,
    color,
    activeRoute,
    { path }
  );
  const itemText = getItemTextClass(classes, miniActive);
  return (
    <div className={classes.newChecklistButton}>
      <List className={classes.list}>
        <ListItem className={classes.item}>
          <div
            onClick={() => createNewChecklist(history)}
            className={navLinkColorClasses}
          >
            <ListItemIcon
              className={classes.itemIcon}
              style={miniActive ? { marginLeft: "3px" } : {}}
            >
              {getItemIcon({ icon: "add" })}
            </ListItemIcon>
            <ListItemText
              primary={"New Checklist"}
              disableTypography={true}
              className={itemText}
            />
          </div>
        </ListItem>
      </List>
    </div>
  );
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrg: false,
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true
    };
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  openCollapse = collapse => {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  };

  setMiniActive = miniActive => {
    this.setState({ miniActive });
  };

  render() {
    const {
      classes,
      color,
      routes,
      bgColor,
      user,
      runTour,
      templates,
      dashboards,
      history
    } = this.props;
    const { openAvatar, openOrg } = this.state;
    const miniActive = this.props.miniActive && this.state.miniActive;

    // Currently routes are all Dashboards
    const hardcodedNavItems = routes.map(route => {
      route.pinnedDashboard = true;
      return route;
    });

    const dashboardItems = dashboards.getLogbooks
      ? dashboards.getLogbooks.items
      : [];

    const templateItems = templates.getChecklistTemplates
      ? templates.getChecklistTemplates.items
      : [];

    const pinnedDashboards = dashboardItems.filter(dashboard => {
      const { dashboardNav } = dashboard.metadata;
      return dashboardNav && dashboardNav.includes(ChecklistNavType.PRIVATE);
    });

    // Keep for now so can toggle to Active from ChecklistDashboard
    // But need to refactor to use checklistNav and figure out UX in ChecklistWidget
    const _pinnedActive = templateItems.filter(
      template => template.metadata.pinned
    );

    const activeTemplates = templateItems.filter(template => {
      const { checklistNav } = template.metadata;
      return checklistNav && checklistNav.includes(ChecklistNavType.ACTIVE);
    });

    const pinnedActive = activeTemplates.concat(_pinnedActive);

    const pinnedTemplate = templateItems.filter(template => {
      const { checklistNav } = template.metadata;
      return (
        checklistNav &&
        (checklistNav.includes(ChecklistNavType.PRIVATE) ||
          checklistNav.includes(ChecklistNavType.TEMPLATE))
      );
    });

    // Only return when profile is available
    if (!user || !user.profile || !user.siteConfig) return null;

    const { siteConfig } = user;

    const userComponent = (
      <UserComponent
        user={user}
        miniActive={miniActive}
        bgColor={bgColor}
        runTour={runTour}
        openAvatar={openAvatar}
        openCollapse={this.openCollapse}
      />
    );

    const organizationComponent = (
      <OrganizationComponent
        classes={classes}
        user={user}
        miniActive={miniActive}
        bgColor={bgColor}
        logoText={siteConfig.name}
        logo={siteConfig.logo}
        openOrg={openOrg}
        openCollapse={this.openCollapse}
      />
    );

    const dashboardLinksActive = createdPinnedDashboardLinks(
      pinnedDashboards,
      true
    );

    const checklistLinksTemplate = createdPinnedTemplateLinks(
      pinnedTemplate,
      true,
      templateItems
    );

    const checklistLinksActive = createdPinnedTemplateLinks(
      pinnedActive,
      true,
      null
    );

    const _routes = hardcodedNavItems.concat(dashboardLinksActive);

    const links = (
      <Links
        routes={_routes}
        color={color}
        miniActive={miniActive}
        activeRoute={this.activeRoute}
        openCollapse={this.openCollapse}
        state={this.state}
      />
    );

    const DashboardLinks = (
      <PinnedDashboard
        classes={classes}
        dynamicLinks={links}
        label="Dashboards"
        miniActive={miniActive}
      />
    );

    const DynamicLinksTemplate = (
      <Links
        routes={checklistLinksTemplate}
        color={color}
        miniActive={miniActive}
        activeRoute={this.activeRoute}
        openCollapse={this.openCollapse}
        state={this.state}
        user={user}
      />
    );

    const pinnedLinksTemplate = (
      <PinnedChecklists
        classes={classes}
        dynamicLinks={DynamicLinksTemplate}
        label="Templates"
        miniActive={miniActive}
      />
    );

    const DynamicLinksActive = (
      <Links
        routes={checklistLinksActive}
        color={color}
        miniActive={miniActive}
        activeRoute={this.activeRoute}
        openCollapse={this.openCollapse}
        state={this.state}
        user={user}
      />
    );

    const pinnedLinksActive = (
      <PinnedChecklists
        classes={classes}
        dynamicLinks={DynamicLinksActive}
        label="Active"
        miniActive={miniActive}
      />
    );

    const newChecklistButton = (
      <NewChecklistButton
        classes={classes}
        miniActive={miniActive}
        color={color}
        activeRoute={this.activeRoute}
        history={history}
      />
    );

    return (
      <div>
        <Hidden mdUp implementation="js">
          <SmallSidebar
            {...this.props}
            userComponent={userComponent}
            organizationComponent={organizationComponent}
            links={links}
            pinnedChecklistsTemplate={pinnedLinksTemplate}
            pinnedChecklistsActive={pinnedLinksActive}
            miniActive={miniActive}
            newChecklistButton={newChecklistButton}
          />
        </Hidden>
        <Hidden smDown implementation="js">
          <LargeSidebar
            {...this.props}
            userComponent={userComponent}
            organizationComponent={organizationComponent}
            links={DashboardLinks}
            pinnedChecklistsTemplate={pinnedLinksTemplate}
            pinnedChecklistsActive={pinnedLinksActive}
            miniActive={miniActive}
            setMiniActive={this.setMiniActive}
            newChecklistButton={newChecklistButton}
          />
        </Hidden>
      </div>
    );
  }
}

// TODO: switch to ES and just query for root checklists and fields needed - dynamically add subchecklists on caret open.
// Will need to update that query on template changes.  Will also need to dynamically query subchecklists then.
export const Templates = graphql(ChecklistTemplatesQuery, {
  name: "templates",
  options: props => {
    return {
      fetchPolicy: "network-only", // Returns undefined without a call sometimes?
      variables: {
        orgId: props.user.currentOrganization.id
      }
    };
  }
});

export const Dashboards = graphql(LogbooksQuery, {
  name: "dashboards",
  options: props => {
    return {
      fetchPolicy: "network-only", // Returns undefined without a call sometimes?
      variables: {
        orgId: props.user.currentOrganization.id
      }
    };
  }
});

Sidebar.defaultProps = {
  bgColor: "blue"
};

// Keeping proptypes here to illustrate ussage since it's configurable
// and may play a role in ChecklistOps configuration
Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  runTour: PropTypes.func
};

export default compose(
  withStyles(sidebarStyle),
  Templates,
  Dashboards
)(Sidebar);
