/** Import React and 3rd party libs */
import React from "react";
import ReactTable from "react-table";
import moment from "moment";
import { Link } from "react-router-dom";

// @material-ui/core components
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

// @material-ui/icons
/** Import material ui icons */

// Components
import Button from "components/CustomButtons/Button.jsx";

// Subcomponents
/** Import components used only by this component - relative path imports */

// Services
import { urlName } from "services/ChecklistService";

// Queries
/** Import all graphql queries */

// Assets
/** Import styles */

// Variables
/** Declare all constant variables */

// Functions

const handleLogbookClick = (items, history, rowInfo) => {
  const item = items.find(obj => obj.id === rowInfo.original.id);
  if (item) {
    const { id, metadata } = item;
    const { name } = metadata;
    const urlSafe = `/app/dashboard/${urlName(name)}?id=${id}&edit=true`;
    history.push(urlSafe);
  }
};

const constructLogbooks = (logbooks, handleDelete) => {
  return logbooks.map(logbook => {
    const { id, metadata } = logbook;

    // const StarButton = (
    //   <Button
    //     key="star"
    //     justIcon
    //     round
    //     simple
    //     onClick={e => {
    //       alert("You've clicked LIKE button on " + metadata.name);
    //       e.stopPropagation();
    //     }}
    //     color="warning"
    //     className="like"
    //   >
    //     <Star />
    //   </Button>
    // );

    const EditButton = (
      <Link key="edit" to={`dashboard?edit=true&id=${id}`}>
        <Button
          justIcon
          round
          simple
          onClick={e => {
            e.stopPropagation();
          }}
          color="info"
          className="edit"
        >
          <Edit />
        </Button>{" "}
      </Link>
    );

    const DeleteButton = (
      <Button
        key="delete"
        justIcon
        round
        simple
        onClick={e => handleDelete(e, id, metadata)}
        color="danger"
        className="remove"
      >
        <Close />
      </Button>
    );

    const Actions = [];

    Actions.push(EditButton);
    Actions.push(DeleteButton);

    return {
      id,
      name: metadata.name,
      tags: metadata.tags.map(tag => tag.toUpperCase()).join(", "),
      sharing: metadata.sharing.type,
      created: moment(metadata.created.time).format("MMM Do YYYY"),
      actions: (
        // we've added some custom button actions
        <div className="actions-right">{Actions}</div>
      )
    };
  });
};

/**
 * Declare JSX Component - class or
 */
const LogbooksTable = ({ items, handleDelete, history }) => {
  const logbooks = constructLogbooks(items, handleDelete);

  return (
    <ReactTable
      data={logbooks}
      // filterable
      columns={[
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Sharing",
          accessor: "sharing"
        },
        {
          Header: "Created",
          accessor: "created"
        },
        {
          Header: "Tags",
          accessor: "tags"
        },
        {
          Header: "Actions",
          accessor: "actions",
          sortable: false,
          filterable: false
        }
      ]}
      getTrProps={(state, rowInfo) => {
        return {
          onClick: () => handleLogbookClick(items, history, rowInfo)
        };
      }}
      defaultPageSize={10}
      showPaginationBottom={true}
      className="-striped -highlight"
    />
  );
};

// Data Connectors

export default LogbooksTable;
