import gql from "graphql-tag";
import { User } from "../fragments";

export const UpdateMyProfileMutation = gql`
  mutation UpdateMyProfileMutation(
    $email: String
    $phone: String
    $callsign: String
    $firstName: String
    $middleName: String
    $lastName: String
    $city: String
    $country: String
    $postalCode: String
    $title: String
    $about: String
    $profilePicUrl: String
    $emailVerified: String
    $phoneVerified: String
  ) {
    updateMyProfile(
      email: $email
      phone: $phone
      callsign: $callsign
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      city: $city
      country: $country
      postalCode: $postalCode
      title: $title
      about: $about
      profilePicUrl: $profilePicUrl
      emailVerified: $emailVerified
      phoneVerified: $phoneVerified
    ) {
      ...UserData
    }
  }
  ${User.data}
`;

export const DeleteMyProfileMutation = gql`
  mutation DeleteMyProfileMutation {
    deleteMyProfile {
      id
    }
  }
`;

export const InviteUserMutation = gql`
  mutation InviteUserMutation(
    $name: String
    $email: String
    $phone: String
    $groups: [OrgUserGroups]
  ) {
    inviteUser(name: $name, email: $email, phone: $phone, groups: $groups)
  }
`;
