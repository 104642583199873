import React from "react";

// Amplify S3 Storage
import { Storage } from "aws-amplify";

// used for making the prop types of this component
import PropTypes from "prop-types";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Publish from "@material-ui/icons/Publish";

import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import { guid } from "services/utility.js";
import { StepType } from "services/StepService";

const ONE_MB = 1024 * 1024; // 1MB
const THREE_MB = 3 * ONE_MB;
const MAX_PHOTO_SIZE = THREE_MB;

const buttonStyle = {
  borderRadius: "3px"
};

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : null,
      uploadSuccessNotification: false,
      showErrorNotification: false,
      errorMessage: ""
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.fileRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    const { step } = this.props;
    // IMAGE_INPUT uses value and IMAGE_DISPLAY uses configuration.
    const propertyType =
      step.type === StepType.IMAGE_INPUT ? "value" : "configuration";
    const jsonConfig =
      step[propertyType] && step[propertyType].json
        ? JSON.parse(step[propertyType].json)
        : {};
    const image_link = jsonConfig.image_link;
    const user_id = jsonConfig.user_id;

    if (image_link) {
      if (!this.isSyncedImage(image_link)) {
        Storage.get(image_link, { level: "protected", identityId: user_id })
          .then(result => {
            if (this._isMounted) {
              this.setState({ imagePreviewUrl: result });
            }
          })
          .catch(err => console.log(err));
      } else {
        Storage.get(image_link, { level: "public" })
          .then(result => {
            if (this._isMounted) {
              this.setState({ imagePreviewUrl: result });
            }
          })
          .catch(err => console.log(err));
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isSyncedImage(image_link) {
    if (image_link.includes(".jpg")) {
      return true;
    }
    if (image_link.includes(".png")) {
      return true;
    }
    return false;
  }

  showNotification() {
    this.setState({ uploadSuccessNotification: true });
    setTimeout(
      function() {
        this.setState({ uploadSuccessNotification: false });
      }.bind(this),
      6000
    );
  }

  showErrorNotification(message) {
    this.setState({ showErrorNotification: true, errorMessage: message });
    setTimeout(
      function() {
        this.setState({ showErrorNotification: false });
      }.bind(this),
      6000
    );
  }

  // Removes the image from the step
  handleImageChange(e, step, updateStep) {
    e.preventDefault();

    const newStep = Object.assign({}, step);

    // IMAGE_INPUT uses value and IMAGE_DISPLAY uses configuration.
    const propertyType =
      step.type === StepType.IMAGE_INPUT ? "value" : "configuration";
    const jsonConfig =
      step[propertyType] && step[propertyType].json
        ? JSON.parse(step[propertyType].json)
        : {};
    const newJsonConfig = Object.assign({}, jsonConfig, {
      image_link: "",
      user_id: ""
    });
    newStep[propertyType] = Object.assign({}, newStep[propertyType], {
      json: JSON.stringify(newJsonConfig)
    });

    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      };
      reader.readAsDataURL(file);
    }

    updateStep(newStep);
  }

  // Saves the image to the step
  handleSubmit(step, updateStep, user) {
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself

    const id = guid();
    const orgId = user.currentOrganization.id;
    const newStep = Object.assign({}, step);

    if (!newStep.value) {
      newStep.value = {};
    }

    const image = this.state.file;
    const { type, size } = image;

    if (size > MAX_PHOTO_SIZE) {
      const message = `Your photo size was ${(size / ONE_MB).toFixed(
        2
      )} MB which is greater than the max size of 3MB.  Please upload a smaller photo.`;
      console.log("WARNING - PHOTO TOO BIG");
      return this.showErrorNotification(message);
    }

    this.setState({ uploading: true }, () => {
      // TODO: need to either figure out an IAM policy that can restrict access to the orgId url
      // to only those users with the orgId in their custom_org claim of their cognito identity token.
      // but may not be available like cognito-identity.amazonaws.com:sub
      // So may need to guard in lambda function, which signs the file request.
      // https://www.serverless.com/blog/s3-one-time-signed-url
      Storage.put(`org-data/${orgId}/${id}`, image, {
        level: "protected",
        contentType: type
        // progressCallback(progress) {
        //   const progressPercent = (progress.loaded / progress.total) * 100;
        //   this.setState({ uploadProgress: progressPercent });
        // }
      })
        .then(result => {
          // IMAGE_INPUT uses value and IMAGE_DISPLAY uses configuration.
          const propertyType =
            step.type === StepType.IMAGE_INPUT ? "value" : "configuration";

          const jsonConfig =
            step[propertyType] && step[propertyType].json
              ? JSON.parse(step[propertyType].json)
              : {};
          const newJsonConfig = Object.assign({}, jsonConfig, {
            image_link: result.key,
            user_id: user.identityId
          });
          newStep[propertyType] = Object.assign({}, newStep[propertyType], {
            json: JSON.stringify(newJsonConfig)
          });

          updateStep(newStep);
          this.setState({ uploading: false }, () => this.showNotification());
        })
        .catch(err => {
          this.setState({
            uploading: false,
            notification: true,
            message: `Error uploading file: ${err}`
          });
        });
    });
  }

  handleClick(disabled) {
    if (disabled) {
      return;
    }
    this.fileRef.current.click();
  }

  handleRemove(step, updateStep) {
    const newStep = Object.assign({}, step);

    // IMAGE_INPUT uses value and IMAGE_DISPLAY uses configuration.
    const propertyType =
      step.type === StepType.IMAGE_INPUT ? "value" : "configuration";
    const jsonConfig =
      step[propertyType] && step[propertyType].json
        ? JSON.parse(step[propertyType].json)
        : {};
    const newJsonConfig = Object.assign({}, jsonConfig, {
      image_link: "",
      user_id: ""
    });
    newStep[propertyType] = Object.assign({}, newStep[propertyType], {
      json: JSON.stringify(newJsonConfig)
    });

    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : null
    });

    this.fileRef.current.value = null;
    updateStep(newStep);
  }
  render() {
    let {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      submitButtonProps,
      step,
      updateStep,
      disabled,
      user
    } = this.props;

    const jsonConfig =
      step.configuration && step.configuration.json
        ? JSON.parse(step.configuration.json)
        : {};

    return (
      <div className="fileinput text-center">
        <input
          type="file"
          onChange={e => this.handleImageChange(e, step, updateStep)}
          ref={this.fileRef}
        />
        {this.state.imagePreviewUrl ? (
          <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
            <img src={this.state.imagePreviewUrl} alt="..." />
          </div>
        ) : null}

        <div>
          {this.state.file === null ? (
            <Button
              style={buttonStyle}
              {...addButtonProps}
              onClick={() => this.handleClick(disabled)}
            >
              {avatar
                ? "Add Photo"
                : this.state.imagePreviewUrl
                ? "Change Image"
                : "Add Image"}
            </Button>
          ) : this.state.uploading ? (
            <div>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <span>
              <Button
                style={buttonStyle}
                {...changeButtonProps}
                onClick={() => this.handleClick()}
              >
                Change
              </Button>
              {avatar ? <br /> : null}
              <Button
                style={buttonStyle}
                {...removeButtonProps}
                onClick={() => this.handleRemove(step, updateStep, user)}
              >
                <i className="fas fa-times" /> Remove
              </Button>
              <Button
                style={buttonStyle}
                {...submitButtonProps}
                onClick={() => this.handleSubmit(step, updateStep, user)}
              >
                <Publish />
                {(step.value && step.value.image_link) || jsonConfig.image_link
                  ? "Success"
                  : "Upload"}
              </Button>
            </span>
          )}
        </div>
        <Snackbar
          place="bc"
          color="success"
          icon={AddAlert}
          message="Image was successfully uploaded."
          open={this.state.uploadSuccessNotification}
          closeNotification={() =>
            this.setState({ uploadSuccessNotification: false })
          }
          close
        />
        <Snackbar
          place="bc"
          color="danger"
          icon={AddAlert}
          message={this.state.errorMessage}
          open={this.state.showErrorNotification}
          closeNotification={() =>
            this.setState({ showErrorNotification: false })
          }
          close
        />
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  submitButtonProps: PropTypes.object,
  step: PropTypes.object,
  updateStep: PropTypes.func,
  disabled: PropTypes.bool
};

export default ImageUpload;
