import React from "react";

import MUIDataTable from "mui-datatables";
import { withRouter } from "react-router-dom";

// Apollo Data Layer
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";

// Datetime handling
import moment from "moment";

// Amplify S3 Image Viewer
import { S3Image } from "aws-amplify-react";

// Lodash
import _ from "lodash";

// @material-ui/core
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

// sub components
import WidgetEditButtons from "../../WidgetComponents/WidgetEditButtons";

// Queries
import { FindInstancesByTemplateIdFullQuery } from "api/queries";

// Services
import { navigateToChecklistLink, urlName } from "services/ChecklistService";
import { currencyFormatter } from "services/utility";

const addRenderer = (column, type, data, classes, rendererConfig, history) => {
  const borderRadius = type === "AVATAR" ? "40px" : 0;
  switch (type) {
    case "TIME":
      column.options.customBodyRender = function renderTime(value) {
        return <div>{moment(value).format("MMM Do YYYY")}</div>;
      };
      break;
    case "CURRENCY":
      console.log("currency");
      column.options.customBodyRender = function renderTime(value) {
        return <div>{currencyFormatter().format(value)}</div>;
      };
      break;
    case "ICON_NAME":
      column.options.customBodyRenderLite = function renderIconName(dataIndex) {
        const { icon, name } = data[dataIndex];
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ marginRight: "12px", opacity: 0.67 }}>{icon}</Icon>
            <span style={{ fontSize: "1.1em", opacity: 0.87 }}>{name}</span>
          </div>
        );
      };
      break;
    case "LIST":
      column.options.customBodyRender = function renderTime(value) {
        return <div>{value.join(", ")}</div>;
      };
      break;
    case "LINKED_CHECKLIST":
      column.options.customBodyRender = function renderTime(value) {
        const { name, checklistId } = value;
        return (
          <div style={{ color: "#00acc1" }}>
            <Tooltip
              id={`tooltip-navigate-to-${checklistId}`}
              title={`Navigate to ${name}`}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
              enterDelay={300}
            >
              <div
                classes={classes.linkStyle}
                onClick={e => {
                  e.stopPropagation();
                  navigateToChecklistLink(
                    name,
                    checklistId,
                    location,
                    history,
                    true
                  );
                }}
              >
                {name}
              </div>
            </Tooltip>
          </div>
        );
      };
      break;
    case "PHOTO":
    case "AVATAR":
      column.options.customBodyRenderLite = function renderTime(dataIndex) {
        const { photo } = data[dataIndex];
        return (
          <S3Image
            imgKey={photo}
            level="public"
            theme={{
              photoImg: {
                maxWidth: "100%",
                width: "40px",
                height: "40px",
                verticalAlign: "middle",
                border: "0",
                borderRadius: borderRadius
              }
            }}
          />
        );
      };
      break;
    case "ACTIONS":
      column.options.customBodyRender = function renderActions(
        value,
        tableMeta
      ) {
        const { rowIndex } = tableMeta;
        const checklist = data[rowIndex];
        const { id, name } = checklist;
        return (
          <div>
            {rendererConfig.includes("EDIT") ? (
              <Tooltip
                id="tooltip-pin-active"
                title="Delete Template"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  aria-label="pin"
                  className={classes.tableActionButton}
                  onClick={e => {
                    const route = `/app/checklist/${urlName(
                      name
                    )}?id=${id}&instance=true&edit=true`;
                    history.push(route);
                    e.stopPropagation();
                  }}
                >
                  <Icon>edit_outlined</Icon>
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        );
      };
  }
};

const getColumns = (config, classes, data, history) => {
  if (!config.columns) {
    return [
      {
        name: "name",
        label: "Name"
      },
      {
        name: "modified",
        label: "Modified"
      }
    ];
  }
  console.log(config.columns);
  const sortedColumns = config.columns.sort((a, b) => a.position - b.position);
  let columns = sortedColumns.map(columnConfig => {
    const column = {
      name: columnConfig.name,
      options: {}
    };
    if (columnConfig.label) {
      column.label = columnConfig.label;
    }
    if (columnConfig.sort !== "undefined") {
      column.options.sort = columnConfig.sort;
    }
    if (columnConfig.filter !== "undefined") {
      column.options.filter = columnConfig.filter;
    }
    if (columnConfig.customRenderer) {
      addRenderer(
        column,
        columnConfig.customRenderer,
        data,
        classes,
        columnConfig.rendererConfig,
        history
      );
    }

    return column;
  });

  return columns;
};

/**
 * Currently table widget is configured with a template id
 * The widget then pulls in all active and completed checklist instances for that template
 * TODO: more work on configuration (can't currently edit in modal)
 */
class WidgetTable extends React.Component {
  constructor(props) {
    super(props);
  }

  constructData = (checklists, config) => {
    const defaultIcon = config.icon || "content_paste";
    const data = checklists.map(checklist => {
      const { metadata, id } = checklist;
      const {
        name,
        version,
        banner,
        created,
        modified,
        sharing,
        tags,
        status,
        checklistNav
      } = metadata;
      const icon =
        banner && banner.icon && banner.icon.link
          ? banner.icon.link
          : defaultIcon;

      const rowData = {
        id,
        name,
        version,
        icon,
        created: created.time,
        modified: modified.time,
        sharing: sharing.type,
        tags,
        status,
        checklistNav,
        checklist
      };

      const columns = config.columns || [];
      columns.forEach(columnConfig => {
        // Only add data if not default metadata added above
        if (columnConfig.accessor) {
          const {
            name,
            stepNum,
            accessor,
            isJSON,
            jsonAccessor
          } = columnConfig;

          let valueData = _.get(checklist.steps[stepNum], accessor);
          if (isJSON) {
            const jsonValue = JSON.parse(valueData);
            valueData = _.get(jsonValue, jsonAccessor);
          }
          rowData[name] = valueData;
        }
      });

      // Functionality: move to, delete, pin to template, pin to active
      return rowData;
    });
    return data;
  };

  render() {
    const {
      classes,
      mode,
      widget,
      editWidget,
      deleteWidget,
      data,
      history
    } = this.props;
    const { configuration } = widget;
    const { findInstancesByTemplateId } = data ? data : {};
    const items =
      findInstancesByTemplateId && findInstancesByTemplateId.items
        ? findInstancesByTemplateId.items
        : [];
    const tableConfig = configuration.json;
    const checklistsData = this.constructData(items, tableConfig);
    const columns = getColumns(tableConfig, classes, checklistsData, history);
    const options = {
      selectableRows: "none",
      onRowClick: (rowData, rowMeta) => {
        const { dataIndex } = rowMeta;
        const checklistInfo = checklistsData[dataIndex];
        const { id, name } = checklistInfo;
        // TODO: probably go to dashboard (click edit there to go to edit checklist)
        const route = `/app/checklist/${urlName(name)}?id=${id}&instance=true`;
        history.push(route);
      }
    };

    const tableIcon = tableConfig.icon;

    return (
      <Card>
        <CardHeader color="success" icon>
          <WidgetEditButtons
            mode={mode}
            classes={classes}
            editWidget={editWidget}
            deleteWidget={deleteWidget}
          />
          <CardIcon color="info">
            <Icon style={{ fontSize: "33px", paddingTop: "5px" }}>
              {tableIcon}
            </Icon>
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{configuration.title}</h4>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={""}
            data={checklistsData}
            columns={columns}
            options={options}
          />
        </CardBody>
      </Card>
    );
  }
}

// TODO: Figure out how to query units for just the property
// Property ID should be in the URL, plus table config should specificy
// Data should get passed in from dashboard, which queries for the property
// Then that id should be used in the query
// GET /instances/_search
// {
//   "query": {
//     "bool": {
//       "filter": [{
//         "term": {
//           "metadata.template.checklistId": "PROPERTY-0000-0000-0000-00000000UNIT"
//         }
//       }, {
//         "nested" : {
//           "path" : "steps",
//           "query": {
//             "term": {
//               "steps.value.checklist.checklistId": "PROPERTY-0052-0000-0000-0000PROPERTY"
//             }
//           }
//         }
//       }]
//     }
//   }
// }
const FindInstancesByTemplateId = graphql(FindInstancesByTemplateIdFullQuery, {
  options: props => {
    return {
      fetchPolicy: "network-only",
      variables: {
        templateId: props.widget.configuration.checklist.checklistId
      }
    };
  },
  skip: props => {
    return !(
      props.widget &&
      props.widget.configuration &&
      props.widget.configuration.checklist &&
      props.widget.configuration.checklist.checklistId
    );
  }
});

export default compose(FindInstancesByTemplateId, withRouter)(WidgetTable);
