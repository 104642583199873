import React from "react";
import PropTypes from "prop-types";
import { flowRight as compose } from "lodash";

import { withRouter } from "react-router-dom";

// Lib for AWS
import { Auth } from "aws-amplify";

// Lib for handling headers
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import FormHelperText from "@material-ui/core/FormHelperText";

// @material-ui/icons
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import ImportantDevices from "@material-ui/icons/ImportantDevices";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// components and services
import {
  verifyEmail,
  verifyPhone,
  verifyPassword,
  getRandomLastName,
  getRandomFullName,
  getRandomString,
  formattedPhone
} from "services/utility";
import Notice from "components/Modal/Notice";
import Terms from "components/Terms/Terms";
import Privacy from "components/Terms/Privacy";
import LoginWithGoogle from "components/Login/LoginWithGoogle";
import LoginWithFacebook from "components/Login/LoginWithFacebook";
import LoginWithAmazon from "components/Login/LoginWithAmazon";
import { SignupConfig } from "services/ConfigService";

// assets
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

class RegisterPage extends React.Component {
  constructor(props) {
    // Currently Optimized for Inivte by Email, autofills email and name, both disabled.
    // So user can only choose a password.
    // TODO: shorten phone link, add phone number, and autofill.
    // Then can pull in phone and username details here.
    const { search, pathname } = props.location;
    const params = new URLSearchParams(search);
    const isInvite =
      pathname === "/pages/invite-page" || pathname === "/pages/r";
    const rawEmail = params.get("email") || "";
    const rawPhone = params.get("p") || "";
    const rawName = params.get("name") || params.get("n") || "";
    const passedEmail = decodeURIComponent(rawEmail);
    const passedPhone = decodeURIComponent(rawPhone);
    const passedName = decodeURIComponent(rawName);
    super(props);
    this.state = {
      firstName: passedName,
      lastName: "",
      username: "",
      email: passedEmail,
      phone: passedPhone,
      password: "",
      termsChecked: true,
      error: {},
      noticeModal: false,
      noticeTitle: "",
      noticeSize: "medium",
      noticeIntermediateAction: false,
      onClose: null,
      showPassword: false,
      isInvite: isInvite
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  updateFirstName = e => {
    this.setState({
      firstName: e.target.value,
      error: {
        firstName: ""
      }
    });
  };

  updateLastName = e => {
    this.setState({
      lastName: e.target.value,
      error: {
        lastName: ""
      }
    });
  };

  updateUserName = e => {
    this.setState({
      username: e.target.value,
      error: {
        username: ""
      }
    });
  };

  updateEmail = e => {
    this.setState({
      email: e.target.value,
      error: {
        email: ""
      }
    });
  };

  updatePhone = e => {
    this.setState({
      phone: e.target.value,
      error: {
        phone: ""
      }
    });
  };

  updatePassword = e => {
    this.setState({
      password: e.target.value,
      error: {
        password: ""
      }
    });
  };

  handleToggle() {
    this.setState({
      termsChecked: !this.state.termsChecked,
      error: {
        terms: ""
      }
    });
  }

  clearForm() {
    this.setState({
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      termsChecked: true,
      error: {}
    });
  }

  handleSubmit = () => {
    const {
      firstName,
      lastName,
      username,
      email,
      phone,
      password,
      termsChecked,
      isInvite
    } = this.state;
    const { user, location } = this.props;

    if (SignupConfig.username && !username) {
      error.username = "Username is required";
      currentError = true;
    }

    let _username = SignupConfig.username ? username : null;
    const randomness = getRandomString(6);
    if (!_username) {
      _username = SignupConfig.lastName
        ? `${firstName}-${lastName}-${randomness}`
        : null;
    }
    if (!_username) {
      const randomLastName = getRandomLastName();
      _username = SignupConfig.firstName
        ? `${firstName}-${randomLastName}-${randomness}`
        : null;
    }
    if (!_username) {
      const randomFullName = getRandomFullName();
      _username = `${randomFullName}-${randomness}`;
    }

    const signupInfo = {
      username: _username,
      password: password,
      attributes: {}
    };

    const error = {};
    let currentError = false;

    const search = location.search;
    const params = new URLSearchParams(search);
    const orgInvite = params.get("invite") || params.get("i");
    const rawEmail = params.get("email") || ""; // only present on email
    const passedEmail = decodeURIComponent(rawEmail);
    const isEmailInvite = orgInvite && passedEmail;
    const isPhoneInvite = orgInvite && !passedEmail;

    if (orgInvite) {
      signupInfo.attributes["custom:org_invite"] = orgInvite;
    }

    if (SignupConfig.firstName) {
      if (!firstName) {
        error.firstName = "First name is required";
        currentError = true;
      } else {
        signupInfo.attributes.given_name = firstName;
      }
    }

    if (SignupConfig.lastName) {
      if (!lastName) {
        error.lastName = "Last name is required";
        currentError = true;
      } else {
        signupInfo.attributes.family_name = lastName;
      }
    }

    if (SignupConfig.email) {
      if (email && !verifyEmail(email)) {
        error.email = "Not a valid email address.";
        currentError = true;
      } else if (SignupConfig.emailOrPhone && !email && !phone) {
        error.email = "Email or phone required";
        currentError = true;
      } else if (!SignupConfig.emailOrPhone && !email) {
        error.email = "Email is required";
        currentError = true;
      } else if (email) {
        signupInfo.attributes.email = email;
      }
    }

    if (SignupConfig.phone) {
      if (phone && !verifyPhone(phone)) {
        error.phone = "Not a valid phone number.";
        currentError = true;
      } else if (SignupConfig.emailOrPhone && !phone && !email) {
        error.phone = "Email or phone required";
        currentError = true;
      } else if (!SignupConfig.emailOrPhone && !phone) {
        error.phone = "Phone is required";
        currentError = true;
      } else if (phone) {
        signupInfo.attributes.phone_number = formattedPhone(phone);
      }
    }

    if (!verifyPassword(password)) {
      error.password =
        "Not a valid password.  Must contain at least 8 characters, one upercase letter, one lowercase letter, one number, and one special character.";
      currentError = true;
    }

    if (!termsChecked) {
      error.terms = "Must agree to the terms of service.";
      currentError = true;
    }

    if (currentError) {
      this.setState({ error });
      return; // Don't submit
    }

    Auth.signUp(signupInfo)
      .then(() => {
        this.clearForm();
        const loginUserIn = () => {
          Auth.signIn(signupInfo.username, password)
            .then(returnedUser => {
              user.login(returnedUser, this.props.location);
            })
            .catch(err => console.log("Error:", err));
        };
        const verifyEmail = email && !isEmailInvite ? email : "";
        const verifyPhone = phone && !isPhoneInvite ? phone : "";
        this.openRegistrationSuccess(
          loginUserIn,
          verifyPhone,
          verifyEmail,
          isInvite
        );
        // Will save user to DB on confirmation with stream job
      })
      .catch(err => {
        this.setState({ error: { general: err } });
      });
  };

  handleClose = () => {
    const { onClose } = this.state;
    if (onClose) {
      onClose();
    } else {
      this.setState({
        noticeModal: false,
        noticeTitle: "",
        onClose: null
      });
    }
  };

  openTerms = () => {
    this.setState({
      noticeModal: true,
      noticeTitle: <Terms />,
      noticeSize: "large"
    });
  };

  openPrivacy = () => {
    this.setState({
      noticeModal: true,
      noticeTitle: <Privacy />,
      noticeSize: "large"
    });
  };

  openError = error => {
    const { classes } = this.props;
    this.setState({
      noticeModal: true,
      noticeTitle: (
        <div>
          <h3 className={classes.danger}>Error Signing In</h3>
          <p>
            There was an error signing in.
            <br />
            <br />
            Email us at <a href="mailto:team@airsync.ai">team@airsync.ai</a> to
            help trouple shoot the issue.
          </p>
          <p>{error}</p>
        </div>
      )
    });
  };

  openRegistrationSuccess = (fn, phone, email, isInvite) => {
    const { classes } = this.props;
    let toVerify;
    if (phone && email) {
      toVerify = "email and phone number by clicking on the links we sent you.";
    } else if (phone) {
      toVerify = "phone number by clicking on the link we texted you.";
    } else if (email) {
      toVerify = "email by clicking on the link we sent you.";
    }

    SignupConfig.phone
      ? SignupConfig.email
        ? "email and / or phone"
        : "phone"
      : "email";
    const verifyMessage = toVerify
      ? `Please verify your ${toVerify}. This will ensure you can recover your account and take advantage of all the functionality Airsync has to offer.`
      : "Please vistit your profile to add and verify your contact information for the best experience";
    this.setState({
      noticeModal: true,
      noticeTitle: (
        <div>
          <h3 className={classes.successColor}>Registration Success</h3>
          <p>
            Thank you for signing up for Airsync!
            <br />
            {isInvite ? null : (
              <div>
                <br />
                {verifyMessage}
                <br />
                <br />
              </div>
            )}
            Feel free to contact us with any issues at{" "}
            <a href="mailto:team@airsync.ai">team@airsync.ai</a>.
          </p>
        </div>
      ),
      noticeSize: "medium",
      onClose: fn,
      noticeIntermediateAction: true
    });
  };

  renderOAuth(classes, user, isInvite) {
    if (!SignupConfig.oath || isInvite) {
      return (
        <div>
          <br />
        </div>
      );
    }

    return (
      <div className={classes.center}>
        {SignupConfig.oath.google ? (
          <>
            <LoginWithGoogle user={user} openErrorNotice={this.openError}>
              <Button justIcon round color="google">
                <i className="fab fa-google" />
              </Button>
            </LoginWithGoogle>
            {` `}
          </>
        ) : null}
        {SignupConfig.oath.facebook ? (
          <>
            <LoginWithFacebook user={user} openErrorNotice={this.openError}>
              <Button justIcon round color="facebook">
                <i className="fab fa-facebook-f" />
              </Button>
            </LoginWithFacebook>
            {` `}
          </>
        ) : null}
        {SignupConfig.oath.amazon ? (
          <>
            <LoginWithAmazon
              id="amazon-root"
              user={user}
              openErrorNotice={this.openError}
            >
              <Button justIcon round color="amazon">
                <i className={`fab fa-amazon ${classes.amazonIcon}`} />
              </Button>
            </LoginWithAmazon>
            {` `}
          </>
        ) : null}
        <h4 className={classes.socialTitle}>or be classical</h4>
      </div>
    );
  }

  renderFirstName(firstName, classes, error, isInvite) {
    if (!SignupConfig.firstName) {
      return null;
    }
    return (
      <CustomInput
        id="firstName"
        success={!error.firstName}
        error={!!error.firstName}
        helpText={error.firstName}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          style: SignupConfig.lastName ? { marginRight: "12px" } : {},
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Face className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          endAdornment: error.firstName ? (
            <InputAdornment position="end">
              <Close className={classes.danger} />
            </InputAdornment>
          ) : null,
          value: firstName,
          type: "text",
          autoComplete: "on",
          onChange: this.updateFirstName,
          placeholder: "First Name...",
          disabled: isInvite
        }}
      />
    );
  }

  renderLastName(classes, error) {
    if (!SignupConfig.lastName) {
      return null;
    }
    return (
      <CustomInput
        id="lastName"
        success={!error.lastName}
        error={!!error.lastName}
        helpText={error.lastName}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          style: SignupConfig.lastName ? { marginLeft: "12px" } : {},
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Face className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          endAdornment: error.lastName ? (
            <InputAdornment position="end">
              <Close className={classes.danger} />
            </InputAdornment>
          ) : (
            undefined
          ),
          type: "text",
          autoComplete: "on",
          onChange: this.updateLastName,
          placeholder: "Last Name..."
        }}
      />
    );
  }

  renderFirstAndLastName(firstName, classes, error, isInvite) {
    if (!SignupConfig.firstName) {
      return null;
    }

    return (
      <div className={classes.firstAndLastName}>
        {this.renderFirstName(firstName, classes, error, isInvite)}
        {this.renderLastName(classes, error)}
      </div>
    );
  }

  renderUsername(classes, error) {
    if (!SignupConfig.username) {
      return null;
    }
    return (
      <CustomInput
        id="userName"
        success={!error.username}
        error={!!error.username}
        helpText={error.username}
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Face className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          endAdornment: error.username ? (
            <InputAdornment position="end">
              <Close className={classes.danger} />
            </InputAdornment>
          ) : null,
          type: "text",
          autoComplete: "on",
          onChange: this.updateUserName,
          placeholder: "Userame..."
        }}
      />
    );
  }

  renderEmail(email, classes, error, isInvite) {
    if (!SignupConfig.email || (isInvite && !email)) {
      return null;
    }

    return (
      <CustomInput
        id="email"
        success={!error.email}
        error={!!error.email}
        helpText={error.email}
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Email className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          endAdornment: error.email ? (
            <InputAdornment position="end">
              <Close className={classes.danger} />
            </InputAdornment>
          ) : null,
          value: email,
          type: "email",
          autoComplete: "on",
          onChange: this.updateEmail,
          placeholder: "Email...",
          disabled: isInvite
        }}
      />
    );
  }

  renderPhone(phone, classes, error, isInvite) {
    if (!SignupConfig.phone || (isInvite && !phone)) {
      return null;
    }
    return (
      <CustomInput
        id="phone"
        success={!error.phone}
        error={!!error.phone}
        helpText={error.phone}
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Phone className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          endAdornment: error.phone ? (
            <InputAdornment position="end">
              <Close className={classes.danger} />
            </InputAdornment>
          ) : null,
          value: phone,
          type: "phone",
          autoComplete: "tel-national",
          onChange: this.updatePhone,
          placeholder: "Phone Number... (required if no email)",
          disabled: isInvite
        }}
      />
    );
  }

  renderPassword(classes, error) {
    return (
      <>
        <CustomInput
          id="password"
          success={!error.password}
          error={!!error.password}
          helpText={error.password}
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses
          }}
          inputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}
              >
                <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
              </InputAdornment>
            ),
            endAdornment: error.password ? (
              <InputAdornment position="end">
                <Close className={classes.danger} />
              </InputAdornment>
            ) : (
              undefined
            ),
            type: this.state.showPassword ? "text" : "password",
            autoComplete: "on",
            onChange: this.updatePassword,
            placeholder: "Password..."
          }}
        />
        <Button
          onClick={() =>
            this.setState({ showPassword: !this.state.showPassword })
          }
          color="info"
          simple
          size="sm"
          style={{ marginTop: "-10px" }}
        >
          {this.state.showPassword ? "Hide Password" : "Show Password"}
        </Button>
      </>
    );
  }

  render() {
    const { classes, user } = this.props;
    const {
      email,
      phone,
      firstName,
      error,
      noticeModal,
      noticeTitle,
      noticeSize,
      noticeIntermediateAction,
      termsChecked,
      isInvite
    } = this.state;

    return (
      <div className={classes.container}>
        <Helmet>
          <title>Airsync Ops | Register</title>
          <meta
            name="description"
            content="Airsync powers modern operations.  Define programable workflows and customized reporting to match your organization's unique needs."
          />
        </Helmet>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>
                {isInvite ? "Accept Invitation" : "Register"}
              </h2>
              <CardBody>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12} md={5}>
                    <InfoArea
                      title="Automate Your Operations"
                      description="Programmable workflows, custom reports, and AI"
                      icon={FlightTakeoff}
                      iconColor="rose"
                    />
                    <InfoArea
                      title="Multi-Device"
                      description="Works where you are, online or off"
                      icon={ImportantDevices}
                      iconColor="primary"
                    />
                    <InfoArea
                      title="Team Collaboration"
                      description="Keep everyone in sync, including the customer"
                      icon={Group}
                      iconColor="info"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={5}>
                    {this.renderOAuth(classes, user, isInvite)}
                    <form className={classes.form}>
                      {this.renderFirstAndLastName(
                        firstName,
                        classes,
                        error,
                        isInvite
                      )}
                      {this.renderUsername(classes, error)}
                      {this.renderEmail(email, classes, error, isInvite)}
                      {this.renderPhone(phone, classes, error, isInvite)}
                      {this.renderPassword(classes, error)}
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel
                        }}
                        control={
                          <Checkbox
                            checked={termsChecked}
                            tabIndex={-1}
                            onClick={() => this.handleToggle()}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            <span
                              onClick={this.openTerms}
                              className={classes.termsLink}
                            >
                              {" "}
                              terms and conditions
                            </span>{" "}
                          </span>
                        }
                      />
                      <div
                        className={
                          classes.checkboxLabel + " " + classes.extraLabel
                        }
                      >
                        and{" "}
                        <span
                          onClick={this.openPrivacy}
                          className={classes.termsLink}
                        >
                          privacy policy
                        </span>
                        .
                        {error.terms || error.general ? (
                          <FormHelperText
                            id={"terms-text"}
                            className={classes.danger}
                          >
                            {error.terms || error.general}
                          </FormHelperText>
                        ) : null}
                      </div>
                      <div className={classes.center}>
                        <Button
                          onClick={this.handleSubmit}
                          round
                          color="primary"
                        >
                          Get started
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Notice
          isOpen={noticeModal}
          title={noticeTitle}
          size={noticeSize}
          handleClose={this.handleClose}
          intermediateAction={noticeIntermediateAction}
        />
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(registerPageStyle))(RegisterPage);
