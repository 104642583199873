function log() {
  console.log.apply(null, arguments);
}

function info() {
  console.info.apply(null, arguments);
}

function warn() {
  console.warn.apply(null, arguments);
}

function error() {
  console.error.apply(null, arguments);
}

export const logger = {
  log: log,
  info: info,
  warn: warn,
  error: error
};

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export function copyObjectWithoutNull(objSrc) {
  const objTgt = {};
  Object.keys(objSrc || {}).forEach(key => {
    if (!objSrc[key]) {
      objTgt[key] = "";
    } else {
      objTgt[key] = objSrc[key];
    }
  });
  return objTgt;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function currencyFormatter(locale) {
  // const _locale = "en-US"
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD"

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
}

// verifies if value is a valid URL
export function verifyUrl(value) {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
}

// Check if a url is absolute (returns true) or relative (false)
// https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
const urlRegex = new RegExp("^(?:[a-z]+:)?//", "i");
export function isAbsolute(url) {
  return urlRegex.test(url);
}

// function that returns true if value is email, false otherwise
export function verifyEmail(value) {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

// function that returns true if value is a phone number, false otherwise
// Maybe switch to lib later since can let through some invalid ones,
// but verify via AWS so as long as doens't break things there it's an optimization
// https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
export function verifyPhone(value) {
  var phoneRex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  if (phoneRex.test(value)) {
    return true;
  }
  return false;
}

// verify password client side
export function verifyPassword(value) {
  if (value.length < 8) {
    return false;
  }

  if (!hasNumber(value)) {
    return false;
  }

  if (!hasLowerCase(value)) {
    return false;
  }

  if (!hasUpperCase(value)) {
    return false;
  }

  if (!hasSpecialChar(value)) {
    return false;
  }

  return true;
}

function hasNumber(str) {
  return /\d/.test(str);
}

function hasLowerCase(str) {
  return /[a-z]/.test(str);
}

function hasUpperCase(str) {
  return /[A-Z]/.test(str);
}

// Use this list https://owasp.org/www-community/password-special-characters
function hasSpecialChar(str) {
  return /[\s!"#$%&')(*+,-./:;<=>?@[\\\]^_`{|}~]/g.test(str);
}

const Environment = {
  PRODUCTION: "production",
  STAGING: "staging",
  LOCAL: "local"
};

export function isProd() {
  return process.env.REACT_APP_ENV === Environment.PRODUCTION;
}

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function getRandomProfilePic() {
  return DEFAULT_AVATARS[getRandomInt(DEFAULT_AVATARS.length)];
}

export function getRandomLastName() {
  return RANDOM_NAMES[getRandomInt(RANDOM_NAMES.length)].last;
}

export function getRandomFullName() {
  const name = RANDOM_NAMES[getRandomInt(RANDOM_NAMES.length)];
  return `${name.first}-${name.last}`;
}

export function getRandomString(length, chars) {
  const _chars = chars
    ? chars
    : "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (var i = length; i > 0; --i)
    result += _chars[Math.floor(Math.random() * _chars.length)];
  return result;
}

// https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
// Keep in sync with server version in utility
export const simpleHash = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};

// Format for cognito
export function formattedPhone(phone) {
  let formattedPhone = phone.replace(/[^0-9]/g, "");
  formattedPhone =
    formattedPhone.length === 10 ? `+1${formattedPhone}` : `+${formattedPhone}`;
  return formattedPhone;
}
// Use to remove __typename
// https://github.com/apollographql/apollo-client/issues/1564
// https://gist.github.com/Billy-/d94b65998501736bfe6521eadc1ab538
export function omitDeep(obj, key) {
  if (Array.isArray(obj)) return omitDeepArrayWalk(obj, key);
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach(i => {
    if (i !== key) {
      const val = obj[i];
      if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === "object" && val !== null)
        newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
}

function omitDeepArrayWalk(arr, key) {
  return arr.map(val => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === "object") return omitDeep(val, key);
    return val;
  });
}

const RANDOM_NAMES = [
  {
    first: "albert",
    last: "ball"
  },
  {
    first: "billy",
    last: "bishop"
  },
  {
    first: "louis",
    last: "bleriot"
  },
  {
    first: "frederick",
    last: "blesse"
  },
  {
    first: "pappy",
    last: "boyington"
  },
  {
    first: "eugene",
    last: "bullard"
  },
  {
    first: "claire",
    last: "chennault"
  },
  {
    first: "jacqueline",
    last: "chochran"
  },
  {
    first: "bessie",
    last: "coleman"
  },
  {
    first: "glenn",
    last: "curtiss"
  },
  {
    first: "jimmy",
    last: "doolittle"
  },
  {
    first: "amelia",
    last: "earhart"
  },
  {
    first: "john",
    last: "glenn"
  },
  {
    first: "gus",
    last: "grissom"
  },
  {
    first: "howard",
    last: "hughes"
  },
  {
    first: "charles",
    last: "lindbergh"
  },
  {
    first: "james",
    last: "mccudden"
  },
  {
    first: "harriet",
    last: "quimby"
  },
  {
    first: "eddie",
    last: "rickenbacker"
  },
  {
    first: "alan",
    last: "shepard"
  },
  {
    first: "william",
    last: "tanner"
  },
  {
    first: "wilbur",
    last: "wright"
  },
  {
    first: "manfred",
    last: "vonrichthofen"
  },
  {
    first: "werner",
    last: "voss"
  },
  {
    first: "chuck",
    last: "yeager"
  }
];

export const DEFAULT_AVATARS = [
  "alan-shepard.jpg",
  "billy-bishop-2.jpg",
  "claire-chennault.jpg",
  "gus-grissom.jpg",
  "jimmy-doolittle.jpg",
  "orville-wright-2.jpg",
  "werner-voss.jpg",
  "albert-ball.jpg",
  "billy-bishop.jpg",
  "eddie-rickenbacker.JPG",
  "harriet-quimby.jpg",
  "john-glenn.jpg",
  "orville-wright-3.jpg",
  "wilbur-wright-2.jpeg",
  "amelia-earhart-2.jpg",
  "charles-lindbergh.jpg",
  "eugene-bullard.jpg",
  "howard-hughes.jpg",
  "john-swigert.jpg",
  "orville-wright.jpg",
  "wilbur-wright-3.jpeg",
  "amelia_earhart.jpg",
  "chuck-yeager-2.jpg",
  "flying-tigers.jpg",
  "jacqueline-chochran-2.jpg",
  "louis-bleriot.jpg",
  "pappy-boyington-2.jpg",
  "wilbur-wright.jpeg",
  "bessie-coleman-1.jpg",
  "chuck-yeager.jpg",
  "frederick-blesse.JPG",
  "jacqueline-cochran.jpg",
  "manfred-von-richthofen-2.jpg",
  "pappy-boyington-3.jpeg",
  "william-tanner.jpg",
  "bessie-coleman-2.jpg",
  "claire-chennault-2.jpg",
  "glenn-curtiss.jpg",
  "james-mccudden.jpg",
  "manfred_von_richthofen.jpg",
  "pappy-boyington.jpg"
];
