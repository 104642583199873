/** Import React and 3rd party libs */
import React, { useState } from "react";
import { graphql } from "react-apollo";
import _, { flowRight as compose } from "lodash";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
import AlarmAdd from "@material-ui/icons/AlarmAdd";
import Settings from "@material-ui/icons/Settings";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Pin from "@material-ui/icons/PinDropOutlined";

// Components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Autocomplete from "components/Autocomplete/Autocomplete.jsx";

// Subcomponents
import StepNote from "../../StepComponents/StepNote";
import ViewTitle from "../../StepComponents/ViewTitle";
import ChecklistInput from "./ChecklistInput";
import StepExpirationEdit from "../../StepComponents/StepEdit/StepExpirationEdit";
import ChecklistTemplateSelector from "../../StepComponents/StepEdit/ChecklistTemplateSelector";
import EventsEnable from "../../StepComponents/StepEdit/EventsEnable";
import EventsActionName from "../../StepComponents/StepEdit/EventsActionName";

// Services
import {
  getCommonTabs,
  EditRow,
  isInputDisabled,
  getIconClass
} from "../StepTypesUtility";
import { getStepRowClass, StepType } from "services/StepService";
import {
  ChecklistMode,
  navigateToChecklistLink
} from "services/ChecklistService";

// Queries
import { ChecklistTemplateQuery } from "api/queries";

// Assets
import stepsStyle from "assets/jss/material-dashboard-pro-react/components/stepsStyle.jsx";

// Variables
/** Declare all constant variables */

// Functions
const StepIcon = ({ classes, step }) => {
  // ballot or assignment_turned_in
  return (
    <TableCell className={classes.tableCheck}>
      <div className={getIconClass(step, classes)}>
        <Icon>assignment_turned_in</Icon>
      </div>
    </TableCell>
  );
};

const StepContent = props => {
  const {
    classes,
    step,
    updateStep,
    isTemplateMode,
    mode,
    user,
    sendEvent,
    history,
    location
  } = props;
  const isDisabled = isInputDisabled(mode);
  const isConfigureDisabled = mode !== ChecklistMode.TEMPLATE_EDIT;
  const name = _.get(step, "value.checklist.name");
  const id = _.get(step, "value.checklist.checklistId");
  let title = <ViewTitle {...props} />;
  if (name && id) {
    title = (
      <a
        onClick={() => {
          console.log("props", props);
          navigateToChecklistLink(name, id, location, history);
        }}
      >
        <ViewTitle {...props} isLink={true} />
      </a>
    );
  }

  return (
    <TableCell className={classes.tableContent}>
      {title}
      {isTemplateMode ? (
        <StepConfigure
          step={step}
          updateStep={updateStep}
          isDisabled={isConfigureDisabled}
          user={user}
        />
      ) : (
        <StepInput
          step={step}
          updateStep={updateStep}
          isDisabled={isDisabled}
          mode={mode}
          sendEvent={sendEvent}
        />
      )}
    </TableCell>
  );
};

const StepConfigure = ({ step, updateStep, isDisabled, user }) => {
  return (
    <ChecklistTemplateSelector
      step={step}
      updateStep={updateStep}
      user={user}
      isDisabled={isDisabled}
      editing={true}
    />
  );
};

const sendSelectEvent = (step, sendEvent) => {
  const eventsEnabled = _.get(step, "eventConfig.isEnabled", false);
  if (eventsEnabled) {
    const jsonMetadata = _.get(step, "eventConfig.metadata", "{}");
    const metadata = JSON.parse(jsonMetadata);
    const eventName = step.name;
    const instanceName = _.get(step, "value.checklist.name");
    const instanceSection = instanceName ? ` - ${instanceName}` : "";
    const eventSelected = metadata.selected || "Selected";
    const eventUnselected = metadata.unselected || "Unselected";
    const eventAction = instanceName ? eventSelected : eventUnselected;
    const eventMessage = `${eventName} ${eventAction} ${instanceSection}`;
    if (sendEvent) {
      sendEvent(eventMessage, step);
    } else {
      console.log("Error: sendEvent not passed in");
    }
  }
};

const StepInput = ({ step, updateStep, isDisabled, mode, sendEvent }) => {
  return (
    <ChecklistInput
      step={step}
      mode={mode}
      updateStep={_step => {
        sendSelectEvent(_step, sendEvent);
        updateStep(_step);
      }}
      isDisabled={isDisabled}
    />
  );
};

const addField = (selectField, step, updateStep) => {
  if (!selectField) {
    return;
  }

  let newStep = Object.assign({}, step);
  if (!step.configuration.checklist.steps) {
    step.configuration.checklist.steps = [];
  }
  step.configuration.checklist.steps.push(selectField);
  newStep.configuration = Object.assign({}, step.configuration);

  updateStep(newStep);
};

const toggleTopicRegistration = (step, updateStep, jsonConfig) => {
  let newStep = Object.assign({}, step);
  step.configuration.json = JSON.stringify(
    Object.assign({}, jsonConfig || {}, {
      isRegistered: !jsonConfig.isRegistered
    })
  );
  newStep.configuration = Object.assign({}, step.configuration);
  updateStep(newStep);
};

const clearFields = (step, updateStep) => {
  let newStep = Object.assign({}, step);
  step.configuration.checklist.steps = [];
  newStep.configuration = Object.assign({}, step.configuration);
  updateStep(newStep);
};

const updateStepAndClear = (step, updateStep, setSelectField) => {
  if (setSelectField) {
    setSelectField(null);
  }
  updateStep(step);
};

/**
 * Completed Step
 *
 * Completed a completed checklist
 */
const Completed = props => {
  const [selectField, setSelectField] = useState(null);
  const {
    classes,
    step,
    updateStep,
    editing,
    setEditState,
    user,
    data,
    noteOpen,
    setNoteState,
    mode
  } = props;
  const editProps = { editing, setEditState, noteOpen, setNoteState };
  const tabs = getCommonTabs(step, updateStep);
  const showRowDividers = true;
  const primaryTableRowClass = getStepRowClass(
    classes,
    showRowDividers,
    editing || noteOpen
  );
  const jsonConfig = JSON.parse(_.get(step, "configuration.json", "{}"));
  tabs.push({
    tabName: "Expiration",
    tabIcon: AlarmAdd,
    tabContent: <StepExpirationEdit step={step} updateStep={updateStep} />
  });

  const { checklist } = step.configuration;
  const template =
    data && data.getChecklistTemplate ? data.getChecklistTemplate : null;
  const availableSteps = template
    ? template.steps.map(step => {
        return {
          label: step.name,
          value: step.stepId,
          type: step.type
        };
      })
    : [];

  const selectInfo = checklist && checklist.steps ? checklist.steps : [];

  const isTemplateMode =
    mode === ChecklistMode.TEMPLATE_EDIT ||
    mode === ChecklistMode.TEMPLATE_VIEW;

  tabs.push({
    tabName: "Events",
    tabIcon: Pin,
    tabContent: (
      <div>
        <div>Event Streams</div>
        <EventsEnable
          step={step}
          updateStep={updateStep}
          mode={mode}
          classes={classes}
        />
        <div>Step Select Item Action Name</div>
        <EventsActionName
          property="selected"
          step={step}
          updateStep={updateStep}
          mode={mode}
          classes={classes}
        />
        <div>Step Unselect Item Action Name</div>
        <EventsActionName
          property="unselected"
          step={step}
          updateStep={updateStep}
          mode={mode}
          classes={classes}
        />
      </div>
    )
  });

  // Old configuration - currently do it inline and selection fields should be part of checklist template
  tabs.push({
    tabName: "Configure",
    tabIcon: Settings,
    tabContent: (
      <span>
        <legend>Choose a Checklist</legend>
        <ChecklistTemplateSelector
          step={step}
          updateStep={step =>
            updateStepAndClear(step, updateStep, setSelectField)
          }
          user={user}
          editing={isTemplateMode}
        />
        <br />
        {step.type === StepType.COMPLETED_CHECKLIST ? (
          <FormControlLabel
            control={
              <Switch
                checked={jsonConfig ? jsonConfig.isRegistered : false}
                onChange={() =>
                  toggleTopicRegistration(step, updateStep, jsonConfig)
                }
                value="event stream"
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
              />
            }
            classes={{
              label: classes.label
            }}
            label="Event Stream"
          />
        ) : null}
        {checklist ? (
          <span>
            <legend>Choose a Field for Selecting</legend>
            {selectInfo.map(field => field.name).join(" - ")}
            <Autocomplete
              placeholder={"Type to find a checklist"}
              value={selectField}
              onChange={val =>
                setSelectField({
                  name: val.label,
                  stepId: val.value,
                  type: val.type
                })
              }
              choices={availableSteps}
            />
            <Button
              onClick={() => addField(selectField, step, updateStep)}
              size="sm"
              color="primary"
              round
            >
              <Add className={classes.icons} />
              Add Field
            </Button>
            <Button
              onClick={() => clearFields(step, updateStep)}
              size="sm"
              color="primary"
              round
            >
              <Close className={classes.icons} />
              Clear Fields
            </Button>
          </span>
        ) : null}
      </span>
    )
  });

  return (
    <>
      <TableRow key={step.stepId} className={primaryTableRowClass}>
        <StepIcon {...props} step={step} />
        <StepContent
          {...props}
          {...editProps}
          step={step}
          isTemplateMode={isTemplateMode}
        />
      </TableRow>
      <EditRow classes={classes} open={editing}>
        <CustomTabs headerColor="gray" tabs={tabs} />
      </EditRow>
      <EditRow classes={classes} open={noteOpen}>
        <StepNote step={step} updateStep={updateStep} />
      </EditRow>
    </>
  );
};

export const ChecklistTemplate = graphql(ChecklistTemplateQuery, {
  options: props => {
    const { step, user } = props;
    const orgId = user.currentOrganization.id;
    const { checklistId } = step.configuration.checklist;
    return {
      fetchPolicy: "network-only",
      variables: {
        orgId,
        id: checklistId
      }
    };
  },
  skip: props => {
    const { step } = props;
    const isChecklist = step.configuration.checklist;
    return !isChecklist;
  }
});

export default compose(
  withRouter,
  ChecklistTemplate,
  withStyles(stepsStyle)
)(Completed);
