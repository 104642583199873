// import Checklist from "./Checklist";
import { omitDeep } from "services/utility.js";
import _ from "lodash";
import queryString from "query-string";

import { redirectOnFirstExecuteChecklist } from "../views/Checklists/ChecklistComponents/ChecklistUrlHandling";

import { getStepValue } from "services/StepService";

import { guid } from "services/utility";

/////////////////////
// ENUMS
/////////////////////

export const RoundingType = {
  NONE: "NONE",
  INTEGER: "INTEGER",
  TENTHS: "TENTHS",
  HUNDREDTHS: "HUNDRETHS"
};

export const OperationType = {
  ADDITION: "ADDITION",
  SUBTRACTION: "SUBTRACTION"
};

// TODO: Custom
export const ExpirationType = {
  NEVER: "NEVER",
  DATE: "DATE",
  TIME: "TIME",
  COUNT: "COUNT",
  SUM: "SUM"
};

/**
 * What a mess this is.
 *
 * Types: Template or Instance (technical), Template, Active, Completed (user - technical = type + status)
 * Mode: Edit or View (depending on perms) - may need to expand with RBAC?
 *
 * Current `mode` is determined by calculateMode(checklist, status, instance, edit)
 * where status, instance, and edit is passed through the URL
 * Can use URL to determine fetch but actual status & instance should be from checklist
 * Can use URL for edit sharing? but actual edit perm should be checked on user
 *
 * Starting refactor with `Checklist State Helpers` at the bottom of this file
 */
export const ChecklistMode = {
  TEMPLATE_VIEW: "TEMPLATE_VIEW",
  TEMPLATE_EDIT: "TEMPLATE_EDIT",
  INSTANCE_EXEC: "INSTANCE_EXEC",
  INSTANCE_DONE: "INSTANCE_DONE",
  INSTANCE_EDIT: "INSTANCE_EDIT"
};

export const ChecklistsModes = {
  TEMPLATE: "TEMPLATE",
  ACTIVE: "ACTIVE",
  COMPLETE: "COMPLETE"
};

export const ChecklistType = {
  TEMPLATE: "TEMPLATE",
  INSTANCE: "INSTANCE"
};

// Currently two status types are used
// completed when the checklist is marked complete
// active when a template is started and not marked complete
// status is null when active
export const StatusType = {
  COMPLETED: "COMPLETED",
  ACTIVE: "ACTIVE"
};

export const InstanceStatus = {
  ACTIVE: "ACTIVE",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR"
};

export const OwnerType = {
  USER: "USER",
  ORGANIZATION: "ORGANIZATION",
  AIRSPACE: "AIRSPACE"
};

export const SharedType = {
  USERS: "USERS", // Share with particular users (user IDs in list)
  ORGANIZATION: "ORGANIZATION", // Share with all members of an organization (owner id)
  PRIVATE: "PRIVATE", // Share with nobody
  PUBLIC: "PUBLIC", // Share with everyone
  AIRSPACE: "AIRSPACE" // Special category of public Airspace shared lists
};

// Specifies the location a checklist is pinned to in the nav
export const ChecklistNavType = {
  PRIVATE: "PRIVATE",
  SHARED: "SHARED",
  TEMPLATE: "TEMPLATE",
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED"
};

/////////////////////
// DEFAULT
/////////////////////

export const NEW_CHECKLIST_DESCRIPTION_MARKER = "A new checklist";

/**
 * WARNING
 *
 * This default checklist populates the database (dynamo db).  If you add a new type to schema
 * make sure to set a default here and not let the type be null.  Apollo will still query the data
 * from the server but will then error reading it from the cache causing problems with updates to other
 * components.  TODO: Need to add validation on the server on creation of new checklists.
 */
export const defaultChecklist = (id, name) => {
  return {
    id: id, // Set on client now
    type: ChecklistType.TEMPLATE,
    metadata: {
      name: name ? name : "New Checklist",
      description: "A new checklist",
      tags: [],
      checklistNav: [],
      sharing: {
        type: SharedType.PRIVATE,
        list: []
      },
      eventConfig: {
        isEnabled: true,
        filters: [],
        metadata: JSON.stringify({}),
        actionNames: {
          created: "Started",
          completed: "Completed"
        }
      }
    },
    steps: []
  };
};

/////////////////////
// EVENT STREAMS
/////////////////////

/**
 * Construct Topics
 *
 * Dynamically determines which topics to send events too.
 *
 * Checklist always has a default topic for itself.
 * Other topics are defined in Step configuration.
 *
 * In Templates - only show in metadata so can easily see all registered topics
 * In Instances - construct dynamically at sendEvent
 *
 * Valid registered topics (have template & instance information) are sent as metadata in Event
 * So can construct things like the following.
 * Customer CheckIn - Marked Complete - 20:32 Jan 1st
 * Technician: John Smith, Tenant: Stan Johnson
 * Unit: 31, Property: 200 Franklin, Worker Order: 024124081h
 *
 * so meta is checklist template name, checklist instance name,
 * plus ids for links
 */
export function constructTopics(checklist) {
  // TODO: figure out mode: active vs complete later - probably different message
  // i.e. if an admin edits a completed checklist
  const isInstance = checklist.type === ChecklistType.INSTANCE;
  const templateId = isInstance
    ? _.get(checklist, "metadata.template.checklistId")
    : checklist.id;
  const templateName = checklist.metadata.name;
  const instanceId = isInstance ? checklist.id : "";
  const instanceName = isInstance ? constructInstanceName(checklist) : "";

  const topics = [
    {
      templateId,
      instanceId,
      type: "Self",
      instanceName,
      templateName
    }
  ];
  checklist.steps.forEach(step => {
    const jsonConfig = JSON.parse(_.get(step, "configuration.json", "{}"));
    if (jsonConfig && jsonConfig.isRegistered) {
      const templateInfo = _.get(step, "configuration.checklist");
      if (templateInfo) {
        // name might be stale (TODO: refresh in step config?)
        const { name, checklistId: templateId } = templateInfo;
        const instanceInfo = _.get(step, "value.checklist", {});
        if (instanceInfo) {
          const { checklistId: instanceId, name: instanceName } = instanceInfo;
          topics.push({
            templateId,
            instanceId,
            type: "Linked Step",
            templateName: name,
            instanceName
          });
        } else {
          console.log(
            "Error: only checklist types should register topics - no instance info"
          );
        }
      } else {
        console.log(
          "Error: only checklist types should register topics - no template info"
        );
      }
    }
  });
  return topics;
}

/**
 * Send Event
 *
 * Used by Step Event creation directly
 *
 * Sends an event to all registered topics.
 * Event is constructed of default and registered metadata.
 * If a topic registered by a step hasn't added metadata yet, it won't recieve an event.
 * i.e. The worker topics doesn't need work order events until a worker is assigned.
 * Events are send by checklist instances.
 * Start and complete send events.
 * Every step completion sends an event.
 * Some steps can send multiple events such as timeclock + geoping.
 */
export function sendEvent(eventMessage, orgId, topics, addEvent) {
  // TODO: could move this to a lambda to minimze calls.
  topics.forEach(topic => {
    const { templateId, instanceId } = topic;
    if (templateId && instanceId) {
      const time = new Date().toISOString();
      const event = {
        orgId,
        templateId,
        instanceId,
        time,
        filters: [],
        displayName: eventMessage,
        metadata: JSON.stringify({}),
        data: JSON.stringify({})
      };
      addEvent(event);
    }
  });
}

// Used by Checklist Event creation
export const sendChecklistEvent = (eventAction, checklist, props) => {
  // TODO: Allow the user to choose the terminology
  const eventsEnabled = checklist.metadata.eventConfig.isEnabled;
  if (eventsEnabled) {
    const eventName = checklist.metadata.name;
    const instanceName = constructInstanceName(checklist);
    const eventMessage = `${eventName} ${eventAction} - ${instanceName}`;
    const topics = constructTopics(checklist);
    const orgId = props.user.currentOrganization.id;
    sendEvent(eventMessage, orgId, topics, props.addEvent);
  } else {
    console.log("Events not enabled for", checklist.metadata.name);
  }
};

/////////////////////
// UTILITY
/////////////////////

export function getId(props) {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  return id;
}

export function getParents(props) {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const parents = params.get("parents");
  return parents;
}

// Returns a safe url name for the checklist
// Routing is actually handled by id, name is just for possible SEO / Looks
export function urlName(name) {
  const nameString = name.replace(/\s+/g, "_").toLowerCase();
  const nameSafe = encodeURIComponent(nameString);
  return nameSafe;
}

export function decodeUrlName(nameSafe) {
  const nameString = decodeURIComponent(nameSafe);
  const name = nameString.replace(/_/g, " ");
  return name;
}

// Returns true if a property key exists on _object
export function propertyExists(_object) {
  return _object && Object.keys(_object).length > 0;
}

export function calculateMode(checklist, status, instance, edit) {
  if (edit && !instance) {
    return ChecklistMode.TEMPLATE_EDIT;
  }

  if (!checklist) {
    return ChecklistMode.TEMPLATE_VIEW;
  }

  switch (checklist.type) {
    case ChecklistType.TEMPLATE:
      return ChecklistMode.TEMPLATE_VIEW;
    case ChecklistType.INSTANCE:
      if (status === "complete") {
        return edit ? ChecklistMode.INSTANCE_EDIT : ChecklistMode.INSTANCE_DONE;
      }
      return ChecklistMode.INSTANCE_EXEC;
    default:
      console.log("Error: Unknown Type");
  }
}

export function cloneChecklistForExecute(checklist) {
  const clonedChecklist = JSON.parse(JSON.stringify(checklist));
  const newChecklist = omitDeep(clonedChecklist, "__typename");
  // Remove values set on server
  delete newChecklist.id;
  delete newChecklist.metadata.version;
  delete newChecklist.metadata.owner;
  delete newChecklist.metadata.created;
  newChecklist.type = ChecklistType.INSTANCE;
  newChecklist.metadata.template = {
    checklistId: checklist.id,
    version: checklist.metadata.version
  };
  newChecklist.metadata.sharing = {
    type: SharedType.PRIVATE,
    list: []
  };

  // NOTE: The path not taken -> creating all subchecklists on root execute
  //       Instead, create each sub on it's execution
  // newChecklist.steps.forEach(step => {
  //   if (step.type === StepType.SUB_CHECKLIST) {
  //     const subChecklist = this.getChecklistbyId(step.configuration.id);
  //     const newSubChecklist = this.executeChecklist(subChecklist);
  //     step.configuration.id = newSubChecklist.id;
  //   }
  // });

  return newChecklist;
}

export const calculateModeInfo = (mode, status) => {
  switch (mode) {
    case ChecklistMode.INSTANCE_DONE:
      return {
        title: "Complete",
        color: "success"
      };
    case ChecklistMode.INSTANCE_EXEC:
      if (status === StatusType.COMPLETED) {
        return {
          title: "Complete",
          color: "success"
        };
      } else {
        return {
          title: "Active",
          color: "warning"
        };
      }
    case ChecklistMode.TEMPLATE_EDIT:
      return {
        title: "Edit",
        color: "info"
      };
    case ChecklistMode.INSTANCE_EDIT:
      return {
        title: "Edit",
        color: "success"
      };
    case ChecklistMode.TEMPLATE_VIEW:
    default:
      return {
        title: "Template",
        color: "info"
      };
  }
};

// Similar functionality as in ChecklistsTable - moving to services,
//    - plus deprecating hardcoded dashboard & widgets for Checklists
// URL handling here for Logbooks - probably need to refactor ChecklsitsUrlHandling.js
export const navigateToChecklist = (checklist, history, edit) => {
  if (checklist) {
    const { id, metadata } = checklist;
    const { name, status } = metadata;
    const editString = edit ? "&edit=true" : "";
    const isInstance = !!metadata.template;
    let instanceString = "";
    let statusString = "";
    if (isInstance) {
      instanceString = "&instance=true";
      if (status === StatusType.COMPLETED) {
        statusString = "&status=complete";
      } else {
        statusString = "&status=active";
      }
    }
    const urlSafe = `/app/checklist/${urlName(
      name
    )}?id=${id}${instanceString}${statusString}${editString}`;
    history.push(urlSafe);
  }
};

// Uses name and id from a checklist link to navigate
export const navigateToChecklistLink = (
  name,
  id,
  location,
  history,
  isInstance
) => {
  const { search } = location;
  const newPath = `/app/checklist/${urlName(name)}`;
  const value = queryString.parse(search);
  const { edit, instance, status } = value;
  let newSearch = `?id=${id}`;
  newSearch = newSearch + `&edit=${edit}`;
  if (instance || isInstance) newSearch = newSearch + `&instance=${instance}`;
  if (status) newSearch = newSearch + `&status=${status}`;

  const newLocation = Object.assign({}, location, {
    pathname: newPath,
    search: newSearch
  });
  history.push(newLocation);
};

function getInstanceNameDefinition(instance, template) {
  // Try to use instanceName saved to instance first then template
  // Should be available and correct for instance version in instance
  // But may not be there for uploaded instances (at least currently - 2020)
  if (
    instance &&
    instance.metadata.instanceName &&
    instance.metadata.instanceName.steps &&
    instance.metadata.instanceName.steps.length > 0
  ) {
    return instance.metadata.instanceName;
  }

  if (
    template &&
    template.metadata.instanceName &&
    template.metadata.instanceName.steps &&
    template.metadata.instanceName.steps.length > 0
  ) {
    return template.metadata.instanceName;
  }

  return null;
}

export function constructNameFromDefinition(nameDefintion, _steps) {
  const steps = [];
  nameDefintion.steps.forEach(stepSelector => {
    const stepInfo = _steps.find(step => step.stepId === stepSelector.stepId);
    if (stepInfo) {
      steps.push(stepInfo);
    }
  });

  const strings = steps
    .map(step => getStepValue(step))
    .filter(str => str !== null);
  const name = strings.join(" ");
  return name;
}

export function constructNameFromNameStep(steps) {
  const nameStep = steps.filter(step => step.name.toLowerCase() === "name");
  const name = nameStep.length > 0 ? getStepValue(nameStep[0]) : "";
  return name;
}

export function constructNameFromMetadata(metadata, id) {
  const templateName = metadata.name;
  const partialId = id.split("-")[0];
  if (!metadata.created) {
    return partialId;
  }
  const [date, time] = metadata.created.time.split("T");
  const dateSlice = date.slice(5);
  const timeSlice = time.slice(0, -5);
  const useId = true;
  const name = useId
    ? `${templateName} (${partialId})`
    : `${templateName} ${dateSlice} ${timeSlice}`;
  return name;
}

// Construct the instance name
// Order:
// 1. Name definition specified in instance or template
// 2. Name step if it exists
// 3. Template name + date created
export function constructInstanceName(instance, template) {
  let name;
  const nameDefintion = getInstanceNameDefinition(instance, template);

  // Use name definition
  if (nameDefintion) {
    name = constructNameFromDefinition(nameDefintion, instance.steps);
  }

  // Use name step
  if (!name) {
    name = constructNameFromNameStep(instance.steps);
  }

  // Use template name and partial id or created date
  if (!name) {
    name = constructNameFromMetadata(instance.metadata, instance.id);
  }

  return name;
}

////////////////////////////////////////////////////
// CHECKLIST APIS (newer version of "checklist functions")
////////////////////////////////////////////////////

// Non-state version of execution - used to create a new instance from a template
// Props needs to contain:
//    - location & history for url redirect
//    - user for query context
//    - createChecklistInstance to execute the query
//    - addEvent to steam events
// TODO: refactor other locations to use this and ditch modal popup
export const newChecklist = (checklist, props, callback) => {
  const { createChecklistInstance, user } = props;
  const orgId = user.currentOrganization.id;
  const newChecklist = cloneChecklistForExecute(checklist);

  createChecklistInstance(
    orgId,
    newChecklist.metadata,
    newChecklist.steps
  ).then(results => {
    const returnedChecklist = results.data.createChecklistInstance;
    const actionName = _.get(
      newChecklist,
      "metadata.eventConfig.actionNames.created",
      "Created"
    );
    sendChecklistEvent(actionName, returnedChecklist, props);
    if (callback) {
      callback(results.data.createChecklistInstance);
    }
    redirectOnFirstExecuteChecklist(props, returnedChecklist.id);
  });
};

// New non-state version of updateTemplate without modal
// TODO: need to move to server or have better handling for update
// Issue is some queries don't return full "template" which could overwrite
// valid data.  So need to make sure have the full current template when update.
// Props needs to contain:
//    - user for query context
//    - createChecklistTemplate to execute the query
export const updateTemplate = (
  template,
  props,
  showNotification,
  navigateTo
) => {
  const { createChecklistTemplate, user } = props;
  const orgId = user.currentOrganization.id;
  const cleanedChecklist = omitDeep(template, "__typename");
  const { metadata, steps, id } = cleanedChecklist;
  createChecklistTemplate(orgId, metadata, steps, id).then(results => {
    if (!results.data.createChecklistTemplate) {
      console.log("error update checklist", results);
      if (showNotification) {
        showNotification("error");
      }
    }
    if (showNotification) {
      showNotification();
    }
    if (navigateTo) {
      goToTemplate(template.id, template.name, props);
    }
  });
};

/**
 * Create a new checklist -> Template
 *
 * @param history - history for navigation
 * @param - config - preconfigured checklist
 *
 * With just history, navigate to a new checklist page but don't save automatically.
 * With config too, create and save a new checklist, then navigate to the page.
 */
export const createNewChecklist = (history, id, name, props) => {
  if (id) {
    const template = defaultChecklist(id, name);
    updateTemplate(template, props, null, true);
  } else {
    const id = guid();
    const path = `/app/checklist/checklist?edit=true&id=${id}`;
    history.push(path);
  }
};

/////////////////////
// URL Handling
/////////////////////

export const goToTemplate = (id, name, props) => {
  const { location, history } = props;
  const newLocation = constructTemplateUrl(location, id, name);
  history.push(newLocation);
};

const constructTemplateUrl = (location, id, name) => {
  // Assume Edit (later need to only go to edit if have perms)
  const newPathname = `/app/checklist/${name}`;
  const newSearch = `?id=${id}&edit=true`;
  return Object.assign({}, location, {
    pathname: newPathname,
    search: newSearch
  });
};

// Checklist State Helpers.
// Use these vs. direct checks so checklist state mangement is easier to track.
export const isTemplate = checklist => {
  return checklist.type === ChecklistType.TEMPLATE;
};

export const isInstance = checklist => {
  return checklist.type === ChecklistType.INSTANCE;
};

export const isActive = checklist => {
  // All non completed instances are active (default is not to set status)
  return (
    isInstance(checklist) && checklist.metadata.status !== StatusType.COMPLETED
  );
};

export const isCompleted = checklist => {
  return (
    isInstance(checklist) && checklist.metadata.status === StatusType.COMPLETED
  );
};
