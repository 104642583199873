import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// Components
import CustomInput from "components/CustomInput/CustomInput";

// Services
import { verifyEmail, verifyPhone, formattedPhone } from "services/utility";

// Assets
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(notificationsStyle);

/**
 * Invite a user with either email or phone
 * Groups only allows selecting one for ease of use and to avoid confustion
 * But users can belong to multiple groups
 */
const InviteUser = ({ isOpen, handleConfirm, handleClose }) => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [name, setName] = useState("");
  const [groups, setGroups] = useState(["MANAGER"]);
  const [nameError, setNameError] = useState("");
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const _handleConfirm = () => {
    if (!name) {
      setNameError("Name is required");
    }
    if (emailOrPhone) {
      const isEmail = verifyEmail(emailOrPhone);
      const isPhone = verifyPhone(emailOrPhone);
      if (!isEmail && !isPhone) {
        setEmailOrPhoneError("Valid email or phone required.");
      } else if (isPhone) {
        const phone = formattedPhone(emailOrPhone);
        handleConfirm(name, null, phone, groups);
      } else {
        handleConfirm(name, emailOrPhone, null, groups);
      }
    }
  };
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal + " " + classes.modalMedium
      }}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="small-modal-slide-title"
      aria-describedby="small-modal-slide-description"
      style={{ textAlign: "left" }}
    >
      <DialogTitle
        id="small-modal-slide-title"
        className={classes.modalHeader}
        style={{ opacity: "0.74" }}
      >
        Invite a user.
        <Button
          justIcon
          className={classes.modalCloseButtonParagraph}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={handleClose}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        id="small-modal-slide-description"
        className={classes.modalBody + " " + classes.modalSmallBody}
      >
        <div>
          <CustomInput
            id="callsign"
            labelText="First Name"
            error={!!nameError}
            helpText={nameError}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              onChange: evt => setName(evt.target.value),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>
                    perm_identity
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            id="emailOrPhone"
            labelText="Email or phone"
            error={!!emailOrPhoneError}
            helpText={emailOrPhoneError}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              onChange: evt => setEmailOrPhone(evt.target.value),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputAdornmentIcon}>email</Icon>
                </InputAdornment>
              )
            }}
          />
          <br />
          <br />
          <FormControl
            className={classes.formControl}
            style={{ minWidth: "140px" }}
          >
            <InputLabel htmlFor="age-native-simple">Permissions</InputLabel>
            <Select
              native
              displayEmpty={false}
              value={groups[0]}
              onChange={evt => setGroups([evt.target.value])}
              inputProps={{
                name: "age",
                id: "age-native-simple"
              }}
            >
              <option value={"ADMIN"}>Admin</option>
              <option value={"EXEC"}>Executive</option>
              <option value={"MANAGER"}>Manager</option>
              <option value={"WORKER"}>Worker</option>
              <option value={"GUEST"}>Guest</option>
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={handleClose}
          color="github"
          style={{ opacity: ".45" }}
          simple
          className={classes.modalSmallFooterFirstButton}
        >
          Never Mind
        </Button>
        <Button
          onClick={_handleConfirm}
          color="info"
          simple
          className={
            classes.modalSmallFooterFirstButton +
            " " +
            classes.modalSmallFooterSecondButton
          }
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InviteUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default InviteUser;
