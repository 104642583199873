import React, { useState } from "react";
import { ApolloConsumer } from "react-apollo";
import _ from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Add from "@material-ui/icons/Add";

// Components
import Button from "components/CustomButtons/Button.jsx";

// Subcomponents
import FormInput from "./FormInput";

// Services
import { ChecklistMode } from "services/ChecklistService";
import { StepType } from "services/StepService";
import { verifyUrl, isAbsolute } from "services/utility";

// Queries
import { CrawlInfoQuery } from "api/queries";

// Assets
import linkStepStyle from "assets/jss/material-dashboard-pro-react/components/linkStepStyle.jsx";

// Functions
const fetchEmbedDetails = async (
  url,
  step,
  mode,
  updateStepTuple,
  setLoading,
  client
) => {
  setLoading(true);
  const editTemplate =
    step.type === StepType.LINK_EMBED && mode === ChecklistMode.TEMPLATE_EDIT;
  const query = ""; // Hardcoded in lambda function for now
  const variables = { url, query };
  const { data } = await client.query({
    query: CrawlInfoQuery,
    variables,
    fetchPolicy: "network-only",
    errorPolicy: "ignore"
  });
  const crawlInfo = JSON.parse(data.crawlInfo);
  if (!crawlInfo || !crawlInfo.title) {
    console.log("ERROR crawling", step);
    // TODO: embed link as link - notify
    setLoading(false);
  } else {
    const newStep = editTemplate
      ? updateConfig(step, url, crawlInfo)
      : updateValue(step, url, crawlInfo);
    updateStepTuple([newStep]);
    setLoading(false);
  }
};

const updateConfig = (step, url, crawlInfo) => {
  const newStep = Object.assign({}, step);
  const newConfiguration = Object.assign({}, step.configuration);
  newConfiguration.link = getLink(url, crawlInfo);
  newStep.configuration = newConfiguration;
  return newStep;
};

const updateValue = (step, url, crawlInfo) => {
  const newStep = Object.assign({}, step);
  const newValue = Object.assign({}, step.value);
  newValue.link = getLink(url, crawlInfo);
  newStep.value = newValue;
  return newStep;
};

const getLink = (url, crawlInfo) => {
  const title =
    crawlInfo.title ||
    crawlInfo.title_og ||
    crawlInfo.title_twitter ||
    crawlInfo.title_h1;
  const description =
    crawlInfo.description ||
    crawlInfo.description_og ||
    crawlInfo.description_twitter;
  const imageSrc = crawlInfo.image || crawlInfo.img_og || crawlInfo.img_twitter;
  const faviconSrc =
    crawlInfo.favicon || crawlInfo.favicon_icon || crawlInfo.favicon_icon_16;
  const link = {
    url,
    title,
    description,
    imageSrc,
    faviconSrc
  };
  return link;
};

/**
 * Link Step Component
 *
 * Handles both the Link Embed and Link Input step types.
 * Embed allows the template creator to embed a link
 *    clickable when the checklist is active or completed.
 * Input allows the template created to embed a link input
 *    the can be entered when active and viewed when completed.
 */
const LinkStep = ({ step, updateStepTuple, mode, editing, classes }) => {
  const [loading, setLoading] = useState(false);
  // Show Input edit in Template to, but input is disabled
  const editTemplate =
    (step.type === StepType.LINK_EMBED &&
      mode === ChecklistMode.TEMPLATE_EDIT) ||
    (step.type === StepType.LINK_INPUT && mode === ChecklistMode.TEMPLATE_EDIT);

  const editActive =
    step.type === StepType.LINK_INPUT && mode === ChecklistMode.INSTANCE_EXEC;

  const editCompleted =
    step.type === StepType.LINK_INPUT &&
    mode === ChecklistMode.TEMPLATE_EDIT &&
    editing;
  const showEdit = editTemplate || editActive || editCompleted;
  const inputDisabled =
    step.type === StepType.LINK_INPUT &&
    (mode === ChecklistMode.TEMPLATE_EDIT ||
      mode === ChecklistMode.TEMPLATE_VIEW);
  const embedDisabled =
    step.type === StepType.LINK_EMBED && mode === ChecklistMode.TEMPLATE_VIEW;
  const isDisabled = inputDisabled || embedDisabled;
  let link;
  let webEmbed;
  if (step.type === StepType.LINK_INPUT) {
    webEmbed = step.value && step.value.link ? step.value.link : "";
    link = _.get(step, "value.link.url", "");
    webEmbed = _.get(step, "value.link", "");
  } else if (step.type === StepType.LINK_EMBED) {
    link = _.get(step, "configuration.link.url", "");
    webEmbed = _.get(step, "configuration.link", "");
  }
  const validLink = link && verifyUrl(link);

  if (loading) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", height: "77px" }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const { url, title, description, faviconSrc, imageSrc } = webEmbed;
  let urlParts;
  try {
    urlParts = url ? new URL(url) : { origin: "" };
  } catch (e) {
    console.log("Unabled to parse URL");
    urlParts = { origin: url };
  }
  // Do I need this for imageSrc too?
  const nonRelativeFavicon = isAbsolute(faviconSrc)
    ? faviconSrc
    : `${urlParts.origin}/${faviconSrc}`;

  if (title) {
    // Everything
    if (description && faviconSrc && imageSrc) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={url}>
          <div className={classes.webEmbed}>
            <div className={classes.webEmbedTextContainer}>
              <div className={classes.webEmbedTitle}>{title}</div>
              <div className={classes.webEmbedDescription}>{description}</div>
              <div className={classes.webEmbedLinkContainer}>
                <img
                  src={nonRelativeFavicon}
                  className={classes.webEmbedFavicon}
                />
                <div className={classes.webEmbedUrl}>{url}</div>
              </div>
            </div>
            <div>
              <img src={imageSrc} className={classes.webEmbedImage} />
            </div>
          </div>
        </a>
      );
    } else {
      // Title, Link, & Favicon
      return (
        <a target="_blank" rel="noopener noreferrer" href={url}>
          <div className={classes.webEmbed}>
            <div className={classes.webEmbedTextContainer}>
              <div className={classes.webEmbedTitle}>{title}</div>
              <div>
                {faviconSrc ? (
                  <img
                    src={nonRelativeFavicon}
                    className={classes.webEmbedFavicon}
                  />
                ) : null}
                <span className={classes.webEmbedUrl}>{url}</span>
              </div>
            </div>
          </div>
        </a>
      );
    }
  }

  if (showEdit) {
    return (
      <>
        <FormInput
          step={step}
          updateStepTuple={updateStepTuple}
          isDisabled={isDisabled}
        />
        {validLink ? (
          <ApolloConsumer>
            {client => (
              <Button
                onClick={() =>
                  fetchEmbedDetails(
                    link,
                    step,
                    mode,
                    updateStepTuple,
                    setLoading,
                    client
                  )
                }
                color="info"
                size="sm"
                style={{ float: "right", marginTop: "-106px" }}
              >
                <Add /> Embed
              </Button>
            )}
          </ApolloConsumer>
        ) : null}
      </>
    );
  } else {
    return (
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <div className={classes.webEmbed}>
          <span className={classes.webEmbedUrl}>{url}</span>
        </div>
      </a>
    );
  }
};

export default withStyles(linkStepStyle)(LinkStep);
